export default {
  Auth: {
    Cognito: {
      identityPoolId: 'eu-west-1:bb27e22d-994b-4632-9864-48aade6b7a3a',
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_j3NCn2KBG',
      userPoolClientId: '4hraga4oplkhgk2tc71fdi6abs'
    }
  },
  API: {
    REST: {
      'AwsAccountsApi':
      {
        endpoint: "https://0np52f1mr7.execute-api.eu-west-1.amazonaws.com/v1",
        region: "eu-west-1"
      }
    }
  }
}
